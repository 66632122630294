import { Typography } from '@/shared';
import { useBreakpoints } from '@/shared/providers/Device';
import { useThemeSection } from '@/shared/providers/SectionContext';
import { ContactPersonStoryblok } from '@/shared/types/storyblok/storyblok-types';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Container from '../Container/Container';
import { Mindentity } from '../Mindentity/Mindentity';
import styles from './ContactPerson.module.scss';
import { SBImage } from '../ImageService/SBImage';

type ContentPersonProps = {
    blok: ContactPersonStoryblok;
    isDark?: boolean;
};

const ContactPerson = (props: ContentPersonProps) => {
    const theme = useThemeSection();
    const isDark = theme === 'Dark' || props.isDark || false;
    const { mind, name, role, image, className } = props?.blok;
    const { isXXSmall, isExtraSmall, isSmall } = useBreakpoints();
    const isMobile = isXXSmall || isExtraSmall || isSmall;

    const joinedRole = (role: string | string[] | undefined) => {
        if (!role) return;
        if (Array.isArray(role)) {
            return role.join(' & ');
        }
        return role;
    };
    const [contact, setContact] = useState({
        fullname: name,
        role: joinedRole(role),
        image: image,
        mindColor: '',
    });

    useEffect(() => {
        if (typeof mind !== 'string' && mind) {
            const { fullname: nameMind, role: roleMind, portrait: imageMind, mind_color } = mind.content || {};
            setContact({
                fullname: nameMind || name,
                role: joinedRole(roleMind) || joinedRole(role),
                image: imageMind || image,
                mindColor: mind_color?.color,
            });
        }
    }, [name, role, image, mind]);

    return (
        <Container
            blok={props.blok}
            className={{
                root: clsx(styles.root, className, {
                    [styles.dark]: isDark,
                }),
                wrap: styles.wrapper,
            }}
            style={{
                root: {
                    paddingTop: '64px',
                },
            }}
            code="standard"
        >
            <div className={styles.infos}>
                <Typography.Compressed darkTheme={isDark} className={styles.name}>
                    {contact?.fullname}
                </Typography.Compressed>
                <Typography.Small darkTheme={isDark} className={styles.role}>
                    {contact?.role}
                </Typography.Small>
            </div>
            {contact?.image?.filename && isMobile && (
                <SBImage image={contact?.image} width={128} height={128} fit="cover" />
            )}
            {contact?.image?.filename && !isMobile && (
                <Mindentity name={contact.fullname || ''} color={contact.mindColor} isDark={isDark}>
                    <SBImage image={contact?.image} width={128} height={128} fit="cover" />
                </Mindentity>
            )}
        </Container>
    );
};

export default ContactPerson;
